.button-custom {
  // height: 38px !important;
  display: flex !important; 
  // min-width: 120px;
  align-items: center;
  border-radius: 4px !important;
  justify-content: center;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  &:disabled {
    cursor: not-allowed;
    border-color: #d9d9d9 !important;
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: transparent !important;
    box-shadow: none;
  }
}