.notify-list-wrapper {
  width: 360px;
  height: calc(100vh - 48px - 2rem); //100vh - header height - footer
  position: absolute;
  top: 56px;
  right: 1rem;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  padding: 1rem;
  overflow: auto;

  .title {
    position: sticky;
    width: 100%;
    background-color: white;
    z-index: 1001;

    .flex-space-between {
      display: flex;
      justify-content: space-between;
    }

    .option-button {
      border: none;
      box-shadow: none;

      :hover {
        background-color: #BEC3C9;
        border-radius: 50% !important;
      }
    }
  }

  .affixed {
    padding-top: 16px;
    transition: padding-top 0.2s ease;
  }
}

.notify-item-wrapper {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;

  .content {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    .blue {
      color: #1677ff;
      font-style: italic;
    }

    .message {}

    .footer {
      font-size: 13px;
      color: #1677ff;

      .bold {
        font-weight: 600;

      }

    }
  }

  .content.gray {
    color: gray !important;

    .footer {
      .bold {
        font-weight: normal;
        color: gray;
      }
    }
  }
}

.notify-item-wrapper:hover {
  cursor: pointer;
  background-color: #e9e9e9;
}

.notify-item-dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #8D949E;
  border: none;
}